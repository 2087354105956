import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { slug } from '@/utils/functions';
import GradientText from '../GradientText';

export default function Card({ item }) {
  const cover = item.files[0] || null;
  const title = `${
    item.title.length > 70 ? item.title.slice(0, 70) + '...' : item.title
  }`;

  return (
    <Link
      className="w-full max-w-[460px] fhd:max-w-full h-fit rounded-lg shadow-lg transition duration-700 border-[1px] border-transparent hover:border-customColorGreen200"
      href={`/conteudo/${slug(item.title)}`}
      aria-label={`Link para o blog conteúdo ${item.title}`}
    >
      {cover && (
        <div className="w-full h-[240px] relative">
          <Image
            src={cover.fileUrl}
            alt={cover.fileName}
            fill
            quality={100}
            className="rounded-t-lg object-cover object-center"
          />
        </div>
      )}
      <div className="px-4 flex flex-col gap-4 items-start justify-start bg-news-card h-[100px] rounded-b-lg">
        <div className="h-fit">
          <GradientText text={title} align="text-start text-lg text-wrap" />
        </div>
      </div>
    </Link>
  );
}
