'use client';

import Image from 'next/image';
import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

export default function HomeCTA2({ cta2Content }) {
  const data = cta2Content;
  const image = data.files[0];

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div
      className="px-6 lg:px-14 2xl:px-24 fhd:px-52 py-10 lg:py-20 2xl:py-28 flex flex-col gap-6 items-center justify-center"
      data-aos="zoom-in"
      data-aos-easing="linear"
      data-aos-duration="500"
    >
      <Image
        src={image.fileUrl}
        alt="Logotipo Valorizza"
        width={220}
        height={220}
        quality={100}
        priority
      />
      <h2 className="text-base md:text-[18px] font-semibold text-customColorGreen200 text-center">
        {data.textarea}
      </h2>
    </div>
  );
}
