import React from "react";
import { Oswald } from "next/font/google";

const oswald = Oswald({
  subsets: ["latin"],
  weight: ["200", "300", "400", "500", "600", "700"],
});

export default function GradientText({ text, align }) {
  return (
    <h2
      className={`w-full h-full bg-gradient-to-r from-customColorGreen200 to-customColorGreen500 text-transparent bg-clip-text font-extrabold py-3 ${
        align
          ? align
          : "text-center text-3xl lg:text-[25px] 2xl:text-[36px] fhd:text-[40px]"
      }`}
    >
      {text}
    </h2>
  );
}
